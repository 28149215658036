.overlay {
  position: absolute;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
}

// WELCOME
#welcome {
  z-index: 50000;
  opacity: 1;
}

#play-overlay {
  svg {
    cursor: pointer;
  }
}

#play-button {
  width: 25% !important;
}
