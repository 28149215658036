/* ––––––––––TYPOGRAPHY–––––––––– */
body {
  font-family: Suisse, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";

  text-align: center;
  color: $color-blue;
}

h1 {
  line-height: 1.2;
  font-size: 2rem;
}

h5 {
  line-height: 1.2;
  font-size: 1.44rem;
}

p,
a {
  line-height: 1.5;
  font-size: 1rem;
}

a.button {
  text-decoration: none;
  text-transform: uppercase;
  color: $color-light-blue;
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-tablet) {
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop) {
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop-l) {
}
