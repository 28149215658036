/* ––––––––––VARS–––––––––– */
$color-blue: #273582;
$color-dark-blue: #0e1534;
$color-light-blue: #c1debb;

$radius-small: 12px;
$radius-medium: 24px;
$radius-large: 32px;

$duration-animation: 0.2s;
$duration-animation-long: 0.3s;

$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-desktop-l: 1440px;
