#scan {
  transition: none; //reset default

  .scanning {
    width: 80vw;
    height: 80vw;
    max-height: 80vh;

    .inner {
      position: relative;
      width: 100%;
      height: 100%;
      opacity: 0.8;

      background: linear-gradient(
            to right,
            $color-light-blue 10px,
            transparent 10px
          )
          0 0,
        linear-gradient(to right, $color-light-blue 10px, transparent 10px) 0
          100%,
        linear-gradient(to left, $color-light-blue 10px, transparent 10px) 100%
          0,
        linear-gradient(to left, $color-light-blue 10px, transparent 10px) 100%
          100%,
        linear-gradient(to bottom, $color-light-blue 10px, transparent 10px) 0 0,
        linear-gradient(to bottom, $color-light-blue 10px, transparent 10px)
          100% 0,
        linear-gradient(to top, $color-light-blue 10px, transparent 10px) 0 100%,
        linear-gradient(to top, $color-light-blue 10px, transparent 10px) 100%
          100%;
      background-repeat: no-repeat;
      background-size: 40px 40px;

      .scanline {
        position: absolute;
        width: 100%;
        height: 10px;
        background: $color-light-blue;
        animation: move 2.5s ease-in-out infinite;
      }
    }
  }
}
