a.button,
.overlays > * {
  transition: all ease-in-out $duration-animation;
}

.hidden {
  display: none !important;
}

.visible {
  display: block;
}

.visuallyhidden {
  opacity: 0 !important;
}
