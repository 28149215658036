/* –––––––––––––––––––––––––––––– GENERAL –––––––––––––––––––––––––––––– */
html,
body {
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  background-color: $color-light-blue;
}

img {
  max-width: 100%;
}

main {
  display: flex;
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 100%;
  max-width: 410px;
  padding: 0 1rem;

  h1 {
    line-height: 1.2;
    font-size: 2rem;
  }

  h5 {
    margin-top: 2rem;
  }

  p,
  a {
    margin-top: 1rem;
  }
}

a.button {
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 2rem;

  margin-top: 3rem;
  background-color: $color-blue;

  &:focus-visible,
  &:focus,
  &:active,
  &:target,
  &:hover {
    box-shadow: 0px 0px 25px 0px rgba(39, 53, 130, 0.5);
  }

  &:active {
    background-color: $color-dark-blue;
    transform: translateY(1px);
  }

  &.locked {
    background-color: unset;
    color: $color-blue;
    // opacity: 0.3;

    cursor: not-allowed;

    &:focus-visible,
    &:focus,
    &:active,
    &:target,
    &:hover {
      box-shadow: unset;
    }
  }

  &.secondary {
    background-color: unset;
    border: 2px solid $color-blue;
    color: $color-blue;
  }
}

/* –––––––––––––––––––––––––––––– TABLET –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-tablet) {
}

/* –––––––––––––––––––––––––––––– DESKTOP –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop) {
}

/* –––––––––––––––––––––––––––––– DESKTOP L –––––––––––––––––––––––––––––– */
@media only screen and (min-width: $breakpoint-desktop-l) {
}
